<template>
  <div class="collectTask">
    <div class="data-table">
      <a-space>
        <a-button type="primary" style="margin-bottom: 10px" @click="handleAddTask">新建任务</a-button>
        <a-button icon="sync" style="margin-bottom: 10px" @click="getTableData">刷新</a-button>
      </a-space>
      <a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false" row-key="id">
        <div slot="taskDetails" slot-scope="text, record">
          <div class="nameLine">
            <span v-show="!record.showInput">{{ record.crawlTaskName || '' }}</span>
            <a-icon v-show="!record.showInput" class="editIcon" type="edit" @click="handleEditName(record)" />
            <a-input v-show="record.showInput" type="text" style="width: 60%" v-model.trim="tempName" />
            <a-button
              :loading="record.btnLoading"
              v-show="record.showInput"
              type="link"
              size="small"
              @click="handleEditConfirm(record)"
              >确定</a-button
            >
            <a-button
              :loading="record.btnLoading"
              v-show="record.showInput"
              type="link"
              size="small"
              @click="handleEditCancel(record)"
              >取消</a-button
            >
          </div>
          <div
            style="cursor: pointer; color: #ae7983"
            v-clipboard:copy="record.id"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
          >
            ID: {{ record.id }}<a-icon style="margin-left: 5px; color: #ae7983" type="copy" />
          </div>
        </div>
        <div slot="taskProcess" slot-scope="text, record">
          <div :class="record.crawlTaskStatus">{{ getTaskState(record.crawlTaskStatus) }}</div>
        </div>
        <div slot="operation" slot-scope="text, record">
          <a-space>
            <a-button v-show="record.crawlTaskStatus === 'SUCCESS'" type="link" @click="handleResult(record.id)">查看结果</a-button>
            <a-button type="link" @click="handelCheck(record.id)">查看任务</a-button>
            <a-popconfirm
              placement="topRight"
              title="确定删除？"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDel(record.id)"
            >
              <a-button type="link">删除</a-button>
            </a-popconfirm>
          </a-space>
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <a-drawer
      :title="isEdit ? '任务详情' : '新建任务'"
      placement="right"
      :width="700"
      :closable="!addLoading"
      :destroyOnClose="true"
      :visible="visible"
      @close="onClose"
      :maskClosable="isEdit"
    >
      <a-form-model ref="addForm" :model="addFormState" :rules="addRules">
        <div style="font-weight: bold">基础信息</div>
        <a-form-model-item label="任务名称" prop="crawlTaskName" :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
          <a-input :disabled="isEdit" v-model.trim="addFormState.crawlTaskName"></a-input>
        </a-form-model-item>
        <div class="titleLine">平台配置</div>
        <a-form-model-item
          label="平台"
          prop="crawlPlatform"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 10 }"
          required
        >
          <a-select v-model="addFormState.crawlPlatform" :disabled="isEdit">
            <a-select-option value="XIAOHONGSHU">小红书</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="采集方式" prop="noteCrawlType" :label-col="{ span: 3 }">
          <a-radio-group
            :options="wayOptions"
            v-model="addFormState.noteCrawlType"
            :default-value="'KEYWORD'"
            @change="handleRadioChange"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item
          key="collectWay"
          prop="collectWay"
          label=" "
          :colon="false"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 15 }"
          v-if="addFormState.noteCrawlType == 'KEYWORD'"
        >
          <template>
            <a-select
              allowClear
              mode="tags"
              style="width: 85%"
              placeholder="请输入关键词"
              v-model="addFormState.collectWay"
              :disabled="isEdit"
            >
            </a-select>
            <span style="margin-left: 10px">{{ keywordLength }}/30</span>
          </template>
        </a-form-model-item>
        <a-form-model-item
          v-else
          label=" "
          :colon="false"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 15 }"
          key="inputAccount"
          prop="inputAccount"
        >
          <a-textarea
            v-model.trim="addFormState.inputAccount"
            :placeholder="
              addFormState.noteCrawlType == 'ACCOUNT'
                ? '请输入平台账号ID，例如小红书号, 英文逗号分割(最多输入300个字)'
                : '笔记分享链接，英文逗号分割(最多输入300个字)'
            "
            :auto-size="{ minRows: 6, maxRows: 8 }"
            :maxLength="300"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item
          v-if="addFormState.noteCrawlType == 'KEYWORD'"
          key="orderType"
          label="搜索排序"
          prop="orderType"
          :label-col="{ span: 3 }"
          required
        >
          <a-radio-group
            :options="sortList"
            v-model="addFormState.orderType"
            :default-value="'NEW'"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              key="crawlerCount"
              v-if="addFormState.noteCrawlType == 'KEYWORD' || addFormState.noteCrawlType == 'ACCOUNT'"
              prop="crawlerCount"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              required
            >
              <template slot="label"
                >采集数量
                <a-tooltip>
                  <template slot="title"> 从平台采集内容的最大数量，受采集方式和指标影响可能达不到最大数量。 </template
                  ><a-icon style="margin: 0 5px; line-height: 45px" type="question-circle" /> </a-tooltip
              ></template>
              <a-select v-model="addFormState.crawlerCount" style="width: 80%" :disabled="isEdit">
                <a-select-option :value="100">100</a-select-option>
                <a-select-option :value="300">300</a-select-option>
                <a-select-option :value="500">500</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              key="publishDay"
              v-if="addFormState.noteCrawlType == 'KEYWORD' && addFormState.orderType != 'HOT'"
              label="发布日期"
              prop="publishDay"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
              required
            >
              <a-select v-model="addFormState.publishDay" :disabled="isEdit">
                <a-select-option :value="3">近3天</a-select-option>
                <a-select-option :value="7">近7天</a-select-option>
                <a-select-option :value="30">近30天</a-select-option>
                <a-select-option :value="60">近60天</a-select-option>
                <a-select-option :value="180">近180天</a-select-option>
                <a-select-option :value="365">近365天</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- <div class="titleLine">
          指标配置<a-tooltip>
            <template slot="title">
              <p>所有指标取交集</p>
              <p>互动量 = 点赞+评价+收藏</p> </template
            ><a-icon style="margin: 0 5px" type="question-circle" />
          </a-tooltip>
        </div>
        <a-row>
          <a-col :span="12"
            ><a-form-model-item label="分享量" prop="shareAmount" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
              <div style="display: flex; align-items: center">
                <a-input type="number" v-model="addFormState.shareAmount" :min="0"></a-input>
                <span style="margin: 0 5px">~</span>
                <a-input type="number" v-model="addFormState.shareAmount" :min="0"></a-input>
              </div> </a-form-model-item
          ></a-col>
          <a-col :span="12"
            ><a-form-model-item label="互动量" prop="actAmount" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
              <div style="display: flex; align-items: center">
                <a-input type="number" v-model="addFormState.actAmount" :min="0"></a-input>
                <span style="margin: 0 5px">~</span>
                <a-input type="number" v-model="addFormState.actAmount" :min="0"></a-input>
              </div> </a-form-model-item
          ></a-col>
        </a-row>
        <a-row>
          <a-col :span="12"
            ><a-form-model-item label="点赞量" prop="likeAmount" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
              <div style="display: flex; align-items: center">
                <a-input type="number" v-model="addFormState.likeAmount" :min="0"></a-input>
                <span style="margin: 0 5px">~</span>
                <a-input type="number" v-model="addFormState.likeAmount" :min="0"></a-input>
              </div> </a-form-model-item
          ></a-col>
          <a-col :span="12"
            ><a-form-model-item label="评价量" prop="actAmount" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
              <div style="display: flex; align-items: center">
                <a-input type="number" v-model="addFormState.actAmount" :min="0"></a-input>
                <span style="margin: 0 5px">~</span>
                <a-input type="number" v-model="addFormState.actAmount" :min="0"></a-input>
              </div> </a-form-model-item
          ></a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="收藏量" prop="subAmount" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
              <div style="display: flex; align-items: center">
                <a-input type="number" v-model="addFormState.subAmount" :min="0"></a-input>
                <span style="margin: 0 5px">~</span>
                <a-input type="number" v-model="addFormState.subAmount" :min="0"></a-input>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row> -->
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-space>
          <a-button v-show="!isEdit" type="primary" @click="handleCollect" :loading="addLoading"> 立即采集 </a-button>
          <a-button v-show="isEdit" type="primary" @click="handleDownload" :loading="addLoading"> 下载结果 </a-button>
          <a-button v-show="isEdit" type="primary" @click="handleCollect" :loading="addLoading"> 再次执行 </a-button>
        </a-space>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import moment from 'moment';
import { downloadExcel } from '@/utils';
import { trackRequest } from '@/utils/track';

export default {
  name: 'collectTask',
  data() {
    const checkWordLength = (rule, value, callback) => {
      let arrTxt = '';
      if (value.length) {
        arrTxt = value.reduce((prev, item) => {
          prev += `${item}`;
          return prev;
        });
      }
      if (arrTxt.length > 30) {
        return callback(new Error('字数不得超过30字'));
      } else {
        return callback();
      }
    };
    const columns = [
      {
        title: '任务名称',
        width: 330,
        scopedSlots: { customRender: 'taskDetails' },
      },
      {
        align: 'center',
        title: '进度',
        dataIndex: 'taskProcess',
        scopedSlots: { customRender: 'taskProcess' },
      },
      {
        title: '开始时间',
        dataIndex: 'taskStartTime',
      },
      {
        title: '完成时间',
        dataIndex: 'taskEndTime',
      },
      {
        title: '创建人',
        dataIndex: 'creator',
      },
      {
        align: 'center',
        title: '操作',
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const addRules = {
      crawlTaskName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      collectWay: [
        { required: true, message: '数据不得为空', trigger: 'blur' },
        { validator: checkWordLength, trigger: 'blur' },
        { validator: checkWordLength, trigger: 'change' },
      ],
      inputAccount: [{ required: true, message: '数据不得为空', trigger: 'blur' }],
    };
    return {
      columns,
      addRules,
      loading: false,
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      visible: false,
      addFormState: {
        collectWay: [],
        crawlPlatform: 'XIAOHONGSHU',
        noteCrawlType: 'KEYWORD',
        orderType: 'NEW',
        crawlerCount: 100,
        publishDay: 3,
        inputAccount: '',
      },
      addLoading: false,
      wayOptions: [
        { label: '关键词', value: 'KEYWORD' },
        { label: '平台账号', value: 'ACCOUNT' },
        { label: '笔记链接', value: 'NOTE_LINK' },
      ],
      sortList: [
        { label: '最新', value: 'NEW' },
        { label: '最热', value: 'HOT' },
      ],
      statusList: [
        {
          value: 'NEW',
          text: '新建',
        },
        {
          value: 'RUNNING',
          text: '运行中',
        },
        {
          value: 'SUCCESS',
          text: '成功',
        },
        {
          value: 'FAILED',
          text: '已失败',
        },
      ],
      isEdit: false,
      tempName: '',
    };
  },
  computed: {
    keywordLength() {
      let arrTxt = '';
      if (this.addFormState.collectWay?.length) {
        arrTxt = this.addFormState.collectWay?.reduce((prev, item) => {
          prev += `${item}`;
          return prev;
        });
      }
      return arrTxt.length;
    },
  },
  components: {},
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      const params = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getCollectTaskList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list.map((val) => {
              val.showInput = false;
              val.btnLoading = false;
              return val;
            });
            this.totalFeatures = data.total;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAddTask() {
      this.visible = true;
      this.isEdit = false;
      this.addFormState.crawlTaskName = `采集任务${moment().format('YYYY-MM-DD')}`;
      this.handleTrack('add_colletion_task');
    },
    handleRadioChange() {
      this.addFormState.collectWay = [];
      this.addFormState.inputAccount = null;
    },
    handleCollect() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.handleTrack('run_task_again', {
            task_id: this.addFormState.id,
          });
          let search = '';
          if (this.addFormState.noteCrawlType == 'KEYWORD') {
            search = this.addFormState.collectWay.join(',');
          } else {
            search = this.addFormState.inputAccount.replace(/\n/g, '').replace(/，/g, ',');
          }
          const params = {
            crawlPlatform: this.addFormState.crawlPlatform,
            crawlTaskName: this.addFormState.crawlTaskName,
            taskConfig: {
              crawlAction: 'XIAOHONGSHU_NOTE',
              crawlerCount: this.addFormState.crawlerCount,
              noteCrawlType: this.addFormState.noteCrawlType,
              orderType: this.addFormState.orderType,
              publishDay: this.addFormState.orderType == 'NEW' ? this.addFormState.publishDay : undefined,
              search,
            },
          };
          console.log(params);
          this.addLoading = true;
          api
            .addCollectTask(params)
            .then(({ code, message }) => {
              if (code === 200) {
                this.getTableData();
                this.onClose();
                this.$message.success('创建成功');
              } else {
                this.$message.error(`${message}`);
              }
            })
            .finally(() => (this.addLoading = false));
        } else {
          return false;
        }
      });

      // const reg = '\n';
      // const strArr = this.inputAccount.replace(new RegExp(reg, 'gm'), ',').split(',');
    },
    async transFile(blob, fileName, fileType) {
      return new window.File([blob, fileName, { type: fileType }]);
    },
    async handleDownload() {
      this.handleTrack('download_colletion', {
        task_id: this.addFormState.id,
      });
      this.addLoading = true;
      const res = await api
        .downloadTaskDetail({
          taskId: this.addFormState.id,
          fileName: this.addFormState.crawlTaskName || '采集任务',
        })
        .finally(() => (this.addLoading = false));
      downloadExcel(res, this.addFormState.crawlTaskName || '采集任务');
    },
    onClose() {
      this.visible = false;
      this.$refs.addForm.clearValidate();
      this.addFormState = {
        collectWay: [],
        crawlPlatform: 'XIAOHONGSHU',
        noteCrawlType: 'KEYWORD',
        orderType: 'NEW',
        crawlerCount: 100,
        publishDay: 3,
        inputAccount: '',
      };
    },
    getTaskState(status) {
      const text = this.statusList.find((val) => val.value === status)?.text || '-';
      return text;
    },
    handleEditName(value) {
      let hasEdit = false;
      this.tableData.forEach((val) => {
        if (val.showInput) hasEdit = true;
      });
      if (hasEdit) {
        this.$message.info('当前有未关闭输入');
      } else {
        this.tempName = value.crawlTaskName;
        value.showInput = true;
      }
    },
    handleEditCancel(value) {
      value.showInput = false;
      this.tempName = '';
    },
    async handleEditConfirm(value) {
      if (!this.tempName) return this.$message.info('名称不得为空');
      value.btnLoading = true;
      const params = {
        id: value.id,
        crawlTaskName: this.tempName,
      };
      api
        .addCollectTask(params)
        .then(() => {
          this.getTableData();
          this.$message.success('修改成功');
        })
        .finally(() => {
          value.btnLoading = false;
          this.handleEditCancel(value);
        });
    },
    handleResult(crawlTaskId) {
      this.$router.push({ name: 'knowledgeStore', query: { crawlTaskId } });
    },
    handelCheck(id) {
      this.handleTrack('view_colletion_task', {
        task_id: id,
      });

      this.visible = true;
      this.isEdit = true;
      const hide = this.$message.loading('查询中...', 0);
      api
        .getCollectTaskDetail(id)
        .then(({ code, data, message }) => {
          if (code == 200) {
            this.addFormState = {
              id: data.id,
              crawlTaskName: data.crawlTaskName,
              crawlPlatform: data.crawlPlatform,
              noteCrawlType: data.taskConfig.noteCrawlType,
              orderType: data.taskConfig.orderType,
              crawlerCount: data.taskConfig.crawlerCount,
              publishDay: data.taskConfig.publishDay,
            };
            if (data.taskConfig.noteCrawlType === 'KEYWORD') {
              this.addFormState.collectWay = data.taskConfig.search.split(',');
            } else {
              this.addFormState.inputAccount = data.taskConfig.search;
            }
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => hide());
    },
    handleDel(id) {
      this.handleTrack('delete_colletion_task', {
        task_id: id,
      });
      api.handleTaskDel(id).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功');
          if (this.tableData.length == 1 && this.pagination.current > 1) this.pagination.current--;
          this.getTableData();
        } else {
          this.$message.error(message);
        }
      });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style scoped lang="less">
.nameLine {
  font-weight: bold;
  color: #333;

  .editIcon {
    opacity: 0;
    color: rgb(126, 126, 241);
    cursor: pointer;
  }

  &:hover {
    .editIcon {
      opacity: 1;
    }
  }
}

.NEW {
  border-radius: 5px;
  color: #2998ff;
  border: 1px solid #2998ff;
  user-select: none;
}

.SUCCESS {
  // SUCCESS
  border-radius: 5px;
  color: rgb(135, 167, 235);
  border: 1px solid rgb(135, 167, 235);
  background-color: rgba(135, 207, 235, 0.082);
  user-select: none;
}

.RUNNING {
  border-radius: 5px;
  color: #facd91;
  border: 1px solid #facd91;
  background-color: #facc9111;
  user-select: none;
}

.FAILED {
  border-radius: 5px;
  color: rgba(255, 0, 0, 0.514);
  border: 1px solid rgba(255, 0, 0, 0.514);
  background-color: rgba(201, 21, 21, 0.014);
  user-select: none;
}

.titleLine {
  margin-top: 45px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin-bottom: 0;
}
</style>
